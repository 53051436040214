<template>
  <div class="container">
    <h2>¿Qué es la Alerta de Violencia de Género contra las Mujeres (AVGM)?</h2>
    <p class="text-start">
      La AVGM es un mecanismo de emergencia que tiene como objetivo “garantizar
      la seguridad de mujeres y niñas, cesar la violencia en su contra y/o
      eliminar las desigualdades producidas por una legislación o política
      pública que agravia sus derechos humanos”, de acuerdo con la
      <a
        href="https://www.gob.mx/cms/uploads/attachment/file/209278/Ley_General_de_Acceso_de_las_Mujeres_a_una_Vida_Libre_de_Violencia.pdf"
        >Ley General de Acceso de las Mujeres a una Vida Libre de Violencia.</a
      >
    </p>
    <div class="containe">
      <div class="row"><p><span class="mapHelp activa me-3"></span>AVGM activa</p></div>
      <div class="row"><p><span class="mapHelp me-3"></span>No activa</p></div>
    </div>
    <div class="mapa_avgm_activa"></div>
    <h2>Incidencia delictiva en la entidad</h2>
    <div class="row">
      <div class="col"><h2>Entidad</h2></div>
      <div class="col"><h2>Año</h2></div>
      <div class="col"><h2>Delito</h2></div>
    </div>
    <div class="row">
      <div class="col-4">
        <select
          name="estados_select_name"
          id="estados_select_incidencia"
          class="select body2"
        ></select>
      </div>
      <div class="col-4">
        <select
          name="year_select_name"
          id="year_select_incidencia"
          class="select body2"
        ></select>
      </div>
      <div class="col-4">
        <select
          name="delito_select_name"
          id="delito_select_incidencia"
          class="select body2"
        ></select>
      </div>
    </div>
    <div class="containerTable">
      <table id="gridIncidenciaAvgm">
        <thead>
          <tr>
            <th><h2>Estado</h2></th>
            <th><h2>Año</h2></th>
            <th><h2>Delito</h2></th>
            <th><h2>Total</h2></th>
            <th><h2>Tasa por cada 100,000</h2></th>
          </tr>
        </thead>
        <tbody></tbody>
      </table>
    </div>
  </div>
</template>

<script>
import * as d3 from "d3v4";
var formatNumber = d3.format(",.2f");
export default {
  async mounted() {
    let div = d3
      .select("body")
      .append("div")
      .attr("class", "tooltip-sp")
      .style("opacity", 0);
    await d3.csv("/mapa.csv", function (error, dataMap) {
      // Mapa de AVGM activa
      d3.xml("/hexbinmap.svg", function (error, datag) {
        // mapa
        d3.select(".mapa_avgm_activa").select("svg").remove();
        d3.select(".mapa_avgm_activa").node().append(datag.documentElement);
        let svg = d3.select(".mapa_avgm_activa").select("svg").select("g");
        let paths = svg.selectAll("path").each(function () {
          let id = d3.select(this)._groups[0][0].id;
          d3.select(this).style("fill", function () {
            let entidadData = getDataEntidadByCve(id);
            if (entidadData.Activa == "True") {
              return "#C7A4FC";
            }
          });
        });
        paths
          .on("mouseover", function () {
            let id = d3.select(this)._groups[0][0].id;
            let entidadData = getDataEntidadByCve(id);
            d3.select(this)
              .transition()
              .duration(200)
              .style("stroke", "#C6C4C4")
              .style("stroke-width", "2px");

            div.transition().duration(1000).style("opacity", 0.99);
            div
              .html(
                "<strong>" +
                  entidadData.ENTIDAD +
                  "</strong><br>" +
                  "<span>Fecha de activación: </span>" +
                  "<strong>" +
                  entidadData.fecha +
                  "</strong>"
              )
              .style("left", d3.event.pageX + "px")
              .style("top", d3.event.pageY - 28 + "px");
          })

          .on("mouseout", function () {
            d3.select(this)
              .transition()
              .duration(200)
              .style("stroke-width", "0px");
            div.transition().duration(700).style("opacity", 0);
          });
        function getDataEntidadByCve(cve) {
          return dataMap.filter((entidad) => entidad.Cve_Ent === cve)[0];
        }
      });
    });

    // Actualiza dropdowns [Estado, Año, Delito] en tasa de incidencia delictica
    await d3.csv("/incidencia_tasa_tabla.csv", (err, data) => {
      // añade estados a dropdown
      (function () {
        d3.select("#estados_select_incidencia").selectAll("option").remove();
        let estados = [...new Set(data.map((d) => d.Estado))];
        let estadosSelect = d3
          .select("#estados_select_incidencia")
          .selectAll("option")
          .data(estados);
        estadosSelect
          .enter()
          .append("option")
          .attr("value", (d) => d)
          .text((d) => d)
          .attr("class", "body2");
      })();
      // añade Años a dropdown
      function updateYear() {
        let estado = d3.select("#estados_select_incidencia").property("value");
        let dataY = data.filter((d) => d.Estado == estado);
        d3.select("#year_select_incidencia").selectAll("option").remove();
        let year = [...new Set(dataY.map((d) => d.Año)), "Todos"].reverse();
        let estadosSelect = d3
          .select("#year_select_incidencia")
          .selectAll("option")
          .data(year);
        estadosSelect
          .enter()
          .append("option")
          .attr("value", (d) => d)
          .text((d) => d)
          .attr("class", "body2");
        updateDelito();
      }
      // añade Delitos a dropdown
      function updateDelito() {
        let estado = d3.select("#estados_select_incidencia").property("value");
        let yearSelected = d3
          .select("#year_select_incidencia")
          .property("value");
        let dataD = [];
        yearSelected == "Todos"
          ? (dataD = data.filter((d) => d.Estado == estado))
          : (dataD = data.filter(
              (d) => d.Estado == estado && d.Año == yearSelected
            ));

        d3.select("#delito_select_incidencia").selectAll("option").remove();
        let delito = ["Todos", ...new Set(dataD.map((d) => d.Delito))];
        let estadosSelect = d3
          .select("#delito_select_incidencia")
          .selectAll("option")
          .data(delito);
        estadosSelect
          .enter()
          .append("option")
          .attr("value", (d) => d)
          .text((d) => d)
          .attr("class", "body2");
        selectDelito();
      }
      function selectDelito() {
        let estado = d3.select("#estados_select_incidencia").property("value");
        let yearSelected = d3
          .select("#year_select_incidencia")
          .property("value");
        let delitoSelected = d3
          .select("#delito_select_incidencia")
          .property("value");
        let dataSd = data.filter((d) => d.Estado == estado);
        if (yearSelected != "Todos") {
          dataSd = dataSd.filter((d) => d.Año == yearSelected);
        }
        if (delitoSelected != "Todos") {
          dataSd = dataSd.filter((d) => d.Delito == delitoSelected);
        }
        d3.select("#gridIncidenciaAvgm")
          .select("tbody")
          .selectAll("tr")
          .remove();
        tableIncidenciasAvgm(dataSd);
      }
      // Actualiza tabla incidencia delictiva y los años al cambiar de estado
      d3.select("#estados_select_incidencia").on("change", () => updateYear());
      d3.select("#year_select_incidencia").on("change", () => updateDelito());
      d3.select("#delito_select_incidencia").on("change", () => selectDelito());
      updateYear(data);
    });
    // funcion para crear tabla
    function tableIncidenciasAvgm(data) {
      let tbodyGastos = d3
        .select("#gridIncidenciaAvgm")
        .select("tbody")
        .selectAll("tr")
        .data(data)
        .enter()
        .append("tr")
        .attr("class", (d, i) => {
          if (i % 2 == 0) {
            return "tr_par";
          }
          return "tr_inpar";
        });
      tbodyGastos.append("td").text((d) => d.Estado);
      tbodyGastos.append("td").text((d) => d.Año);
      tbodyGastos.append("td").text((d) => d.Delito);
      tbodyGastos.append("td").text((d) => d.Total);
      tbodyGastos
        .append("td")
        .text((d) => formatNumber(d["Tasa por cada 100 mil mujeres"]));
    }
  },
  name: "EstadosAlertaActiva",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.mapHelp {
  background-color: #bcbabf;
    width: 15px;
    height: 15px;
    display: inline-block;
    border-radius: 2px;
}
.activa {
  background-color: #C7A4FC;
}
.containerTable {
  display: flex;
  justify-content: center;
  margin: 50px 5px;
}
.containerTable table {
  width: 100%;
}
@media (min-width: 768px) {
  .mapa_avgm_activa {
    width: 50%;
    margin: auto;
  }
}
</style>
